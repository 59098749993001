import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    title = 'toca-filmes-webapp';

    ngOnInit(): void {
        const host = window.location.href;

        if (/^.*http:\/\/tocafilmes.com.*/.test(host)) {
            window.location.href = 'https://tocafilmes.com';
        }

        if (/^.*http:\/\/www.tocafilmes.com.*/.test(host)) {
            window.location.href = 'https://tocafilmes.com';
        }
    }
}
