import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [{
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
}, {
    path: 'pages',
    children: [{
        path: '',
        loadChildren: () => import('./pages/pages.module').then(module => module.PagesModule)
    }]
}, {
    path: '**',
    redirectTo: 'pages'
}];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
