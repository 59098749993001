import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { NgxMasonryModule } from 'ngx-masonry';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {GALLERY_CONFIG, GalleryModule} from 'ng-gallery';

const MAIN_COMPONENTS = [
    AppComponent
];

const COMPONENTS = [
    ...MAIN_COMPONENTS
];

const IMPORTS = [
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    NgxMasonryModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    GalleryModule
];

const SERVICES = [
    {
        provide: GALLERY_CONFIG,
        useValue: {
            dots: true,
            imageSize: 'cover'
        }
    }
]

@NgModule({
    declarations: [
        ...COMPONENTS
    ],
    imports: [
        ...IMPORTS
    ],
    bootstrap: [
        ...MAIN_COMPONENTS
    ],
    providers: [
        ...SERVICES
    ]
})
export class AppModule {
}
